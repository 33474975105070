<template>
  <div class="profile">
    <Header />
    <HeaderBottomProfile />
    <b-container class="profile-container">
      <div
        v-if="(user.individual ? user.individual : user.institutional).investors_risk_profile == null && (user.individual ? user.individual : user.institutional).is_fbonds !== 1">
        <CompleteTheProfile />
      </div>
      <b-row class="justify-content-md-center">
        <div class="col-lg-8 col-md-6">
          <div>
            <b-card class="product-performance mb-5">
              <div class="d-none d-lg-block">
                <b-row>
                  <div class="profile-tab">
                    <b-row class="justify-content-center">
                      <div class="myprofile">
                        <b-col>
                          <h3><b-link to="/profile">Profil</b-link></h3>
                        </b-col>
                      </div>
                      <div class="profile-identity">
                        <b-col>
                          <h3>
                            <b-link to="/profile/identity">Data Diri</b-link>
                          </h3>
                        </b-col>
                      </div>
                      <div class="payment-account">
                        <b-col>
                          <h3>
                            <b-link to="/profile/payment-account">Rekening Bank</b-link>
                          </h3>
                        </b-col>
                      </div>
                      <div class="setting">
                        <b-col>
                          <h3>
                            <b-link href="#">
                              <img src="@/assets/img/icons/setting/setting-icon.svg" alt="Setting Icon"
                                class="w-20" /></b-link>
                          </h3>
                        </b-col>
                      </div>
                    </b-row>
                  </div>
                </b-row>
              </div>
              <div class="d-block d-lg-none">
                <b-row>
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-tab">
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="myprofile">
                              <b-col>
                                <h3><b-link to="/profile">Profil</b-link></h3>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link to="/profile/identity">Data Diri</b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center mb-2">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="profile-identity">
                              <b-col>
                                <b-link to="/profile/payment-account">Rekening Bank</b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                      <b-row class="justify-content-center">
                        <div class="col-lg-12 col-md-6">
                          <b-row class="justify-content-center">
                            <div class="setting">
                              <b-col>
                                <b-link href="#">
                                  <img src="@/assets/img/icons/setting/setting-icon.svg" alt="Setting Icon"
                                    class="w-20" /></b-link>
                              </b-col>
                            </div>
                          </b-row>
                        </div>
                      </b-row>
                    </div>
                  </div>
                </b-row>
              </div>
              <hr />
              <div class="profile-data">
                <b-row class="my-2" v-if="(user.individual ? user.individual : user.institutional).ifua != null">
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-name">
                      <div
                        v-if="(user.individual ? user.individual : user.institutional).investors_risk_profile != null">
                        <!-- <b-link href="/profile/change-profile-identity">
                          <div class="row">
                            <div class="col-10 col-lg-11 mt-2">
                              <p>
                                <img
                                  src="@/assets/img/icons/profile/profile-icon.svg"
                                  alt="Profile Icon"
                                  class="w-20 mx-2"
                                />Ubah Data Diri
                              </p>
                            </div>
                            <div class="col-1 mt-2 text-right">
                              <p>
                                <b-icon
                                  style="color: #6b7280"
                                  icon="chevron-right"
                                ></b-icon>
                              </p>
                            </div>
                          </div>
                        </b-link> -->
                      </div>
                      <div v-else>
                        <!-- <b-link href="/pembukaan-akun">
                          <div class="row">
                            <div class="col-10 col-lg-11 mt-2">
                              <p>
                                <img
                                  src="@/assets/img/icons/profile/profile-icon.svg"
                                  alt="Profile Icon"
                                  class="w-20 mx-2"
                                />Ubah Data Diri
                              </p>
                            </div>
                            <div class="col-1 mt-2 text-right">
                              <p>
                                <b-icon
                                  style="color: #6b7280"
                                  icon="chevron-right"
                                ></b-icon>
                              </p>
                            </div>
                          </div>
                        </b-link> -->
                      </div>
                    </div>
                  </div>
                </b-row>
                <b-row class="my-2" v-if="(user.individual ? user.individual : user.institutional) != null">
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-name">
                      <b-link href="/profile/change-pin">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img src="@/assets/img/icons/profile/profile-icon.svg" alt="Key Icon"
                                class="w-20 mx-2" />Ganti PIN
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon style="color: #6b7280" icon="chevron-right"></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                    <div class="profile-name">
                      <b-link href="/profile/forgot-pin">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img src="@/assets/img/icons/profile/profile-icon.svg" alt="Key Icon"
                                class="w-20 mx-2" />Lupa PIN
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon style="color: #6b7280" icon="chevron-right"></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                    <div class="profile-name">
                      <b-link href="/profile/change-password">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img src="@/assets/img/icons/profile/key-icon.svg" alt="Key Icon"
                                class="w-20 mx-2" />Ganti Password
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon style="color: #6b7280" icon="chevron-right"></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                  </div>
                </b-row>
                <b-row class="my-2" v-if="(user.individual ? user.individual : user.institutional).ifua != null">
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-name">
                      <b-link href="/profile/change-risk-profile">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img src="@/assets/img/icons/profile/risk-profile-icon.svg" alt="Risk Profile Icon"
                                class="w-20 mx-2" />Ubah Profil Risiko
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon style="color: #6b7280" icon="chevron-right"></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                  </div>
                </b-row>
                <!-- <b-row class="my-2" v-if="(user.individual ? user.individual : user.institutional).ifua != null">
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-name">
                      <b-link href="/profile/change-email">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img src="@/assets/img/icons/profile/email-icon.svg" alt="Email Icon"
                                class="w-20 mx-2" />Ubah Email
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon style="color: #6b7280" icon="chevron-right"></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                  </div>
                </b-row> -->
                <!-- <b-row class="my-2" v-if="(user.individual ? user.individual : user.institutional).ifua != null">
                  <div class="col-lg-12 col-md-6">
                    <div class="profile-name">
                      <b-link href="/profile/change-phone">
                        <div class="row">
                          <div class="col-10 col-lg-11 mt-2">
                            <p>
                              <img
                                src="@/assets/img/icons/profile/phone-icon.svg"
                                alt="Phone Icon"
                                class="w-20 mx-2"
                              />Ubah Nomor HP
                            </p>
                          </div>
                          <div class="col-1 mt-2 text-right">
                            <p>
                              <b-icon
                                style="color: #6b7280"
                                icon="chevron-right"
                              ></b-icon>
                            </p>
                          </div>
                        </div>
                      </b-link>
                    </div>
                  </div>
                </b-row> -->
              </div>
            </b-card>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div>
            <PortfolioSidebar />
            <TransactionHistorySidebar />
          </div>
        </div>
      </b-row>
    </b-container>
    <Footer />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import HeaderBottomProfile from "@/components/partials/header/HeaderBottomProfile.vue";
import CompleteTheProfile from "@/components/partials/profile/CompleteTheProfile.vue";
import PortfolioSidebar from "@/components/partials/profile/PortfolioSidebar.vue";
import TransactionHistorySidebar from "@/components/partials/profile/TransactionHistorySidebar.vue";
import Footer from "@/components/partials/footer/Footer.vue";

export default {
  name: "Setting",
  metaInfo: {
    title: "Setting | CGS iTrade Fund",
  },
  components: {
    Header,
    HeaderBottomProfile,
    CompleteTheProfile,
    PortfolioSidebar,
    TransactionHistorySidebar,
    Footer,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      check: "auth/check",
      user: "auth/user",
    }),
  },
};
</script>

<style lang="scss" scoped>
.profile {
  background: #f3f4f6;
}

.profile-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;

  color: #4b5563;
}

hr {
  margin: 0 !important;
}

.myprofile {
  margin-left: 10px;

  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-identity {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.payment-account {
  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.setting {
  border-bottom: 3px solid $secondaryColor;

  a {
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    text-align: center;
    color: #111827;
    text-decoration: none;
  }
}

.profile-data {
  padding: 10px 20px 10px 20px;
  border-radius: 6px;

  .profile-image {
    img {
      border-radius: 6px;
      width: 40px;
    }
  }

  .profile-name {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #111827;
    }
  }

  .title {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #111827;
  }

  .payment-account-profile {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }

    h3 {
      font-style: normal;
      font-weight: bold;
      font-size: 16px;

      color: #111827;
    }
  }

  a {
    text-decoration: none;
  }

  .bank-image {
    img {
      width: 60px;
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;

      color: #4b5563;
      margin-bottom: 0px;
    }
  }

  .btn-edit {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }
}

@media only screen and (max-width: 768px) {
  p {
    font-size: 14px !important;
  }

  .myprofile {
    margin-left: 0px;

    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .profile-identity {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .payment-account {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .setting {
    a {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
</style>
